import React, { useState, useCallback, useRef } from 'react';
import { 
  DataGrid, 
  GridToolbar,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  useTheme,
  Typography,
  Paper,
  IconButton,
  Chip,
  Stack,
  Container,
} from '@mui/material';
import { 
  Add as AddIcon, 
  Delete as DeleteIcon, 
  Edit as EditIcon,
  UploadFile as UploadFileIcon,
  CloudUpload as CloudUploadIcon,
  Attachment as AttachmentIcon,
} from '@mui/icons-material';
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';

const documentTypes = [
  'PDF',
  'Word',
  'Text',
  'HTML',
  'Other',
];

const DocumentManagementGrid = ({ data, isLoading, onAddDocument, onUpdateDocument, onDeleteDocuments }) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentDocument, setCurrentDocument] = useState({
    title: '', 
    description: '', 
    type: 'PDF', 
    tags: [],
    file: null,
    fileName: '',
    sourceSystem: '',
    createdDate: null,
    lastModified: null,
    status: 'Active',
    author: '',
    accessLevel: 'Private',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [newTag, setNewTag] = useState('');
  const fileInputRef = useRef(null);
  
  const handleOpenDialog = useCallback((document = {
    title: '', 
    description: '', 
    type: 'PDF', 
    tags: [],
    file: null,
    fileName: '',
    sourceSystem: '',
    createdDate: null,
    lastModified: null,
    status: 'Active',
    author: '',
    accessLevel: 'Private',
  }, edit = false) => {
    setCurrentDocument({
      ...document,
      createdDate: document.createdDate ? parseISO(document.createdDate) : null,
      lastModified: document.lastModified ? parseISO(document.lastModified) : null,
      tags: Array.isArray(document.tags) ? document.tags : [],
    });
    setIsEditing(edit);
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setCurrentDocument({
      title: '', 
      description: '', 
      type: 'PDF', 
      tags: [],
      file: null,
      fileName: '',
      sourceSystem: '',
      createdDate: null,
      lastModified: null,
      status: 'Active',
      author: '',
      accessLevel: 'Private',
    });
    setIsEditing(false);
    setNewTag('');
  }, []);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setCurrentDocument((prevDoc) => ({
      ...prevDoc,
      [name]: value,
    }));
  }, []);

  const handleDateChange = useCallback((name, date) => {
    setCurrentDocument((prevDoc) => ({
      ...prevDoc,
      [name]: date,
    }));
  }, []);

  const handleAddTag = useCallback(() => {
    if (newTag.trim() !== '') {
      setCurrentDocument((prevDoc) => ({
        ...prevDoc,
        tags: [...prevDoc.tags, newTag.trim()]
      }));
      setNewTag('');
    }
  }, [newTag]);

  const handleDeleteTag = useCallback((tagToDelete) => {
    setCurrentDocument((prevDoc) => ({
      ...prevDoc,
      tags: prevDoc.tags.filter(tag => tag !== tagToDelete)
    }));
  }, []);

  const handleFileChange = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setCurrentDocument((prevDoc) => ({
        ...prevDoc,
        file: file,
        fileName: file.name,
        // Auto-detect document type from file extension
        type: getDocumentTypeFromFileName(file.name) || prevDoc.type
      }));
    }
  }, []);

  const getDocumentTypeFromFileName = (fileName) => {
    const ext = fileName.split('.').pop().toLowerCase();
    
    if (['pdf'].includes(ext)) return 'PDF';
    if (['doc', 'docx'].includes(ext)) return 'Word';
    if (['txt'].includes(ext)) return 'Text';
    if (['html', 'htm'].includes(ext)) return 'HTML';
    
    return null;
  };

  const handleSaveDocument = useCallback(() => {
    const documentToSave = {
      ...currentDocument,
      createdDate: currentDocument.createdDate ? format(currentDocument.createdDate, 'yyyy-MM-dd') : null,
      lastModified: currentDocument.lastModified ? format(currentDocument.lastModified, 'yyyy-MM-dd') : null,
    };
    
    if (isEditing) {
      onUpdateDocument(documentToSave);
    } else {
      onAddDocument(documentToSave);
    }
    handleCloseDialog();
  }, [currentDocument, isEditing, onUpdateDocument, onAddDocument, handleCloseDialog]);

  const handleDeleteSelected = useCallback(() => {
    onDeleteDocuments(selectedRows);
    setSelectedRows([]);
  }, [selectedRows, onDeleteDocuments]);

  const handleTagInputKeyPress = useCallback((e) => {
    if (e.key === 'Enter' && newTag.trim() !== '') {
      handleAddTag();
      e.preventDefault();
    }
  }, [newTag, handleAddTag]);

  const columns = [
    {
      field: "name",
      headerName: "Title",
      flex: 2,
    },
    {
      field: "file_type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "author",
      headerName: "Author",
      flex: 1,
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 2,
      renderCell: (params) => {
        const tags = params.value || [];
        return (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {tags.map((tag, index) => (
              <Chip key={index} label={tag} size="small" />
            )).slice(0, 3)}
            {tags.length > 3 && <Chip label={`+${tags.length - 3}`} size="small" />}
          </Box>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created",
      flex: 1,
      valueFormatter: (params) => params.value ? format(parseISO(params.value), 'MM/dd/yyyy') : '',
    },
    {
      field: "updated_at",
      headerName: "Modified",
      flex: 1,
      valueFormatter: (params) => params.value ? format(parseISO(params.value), 'MM/dd/yyyy') : '',
    },
    {
      field: "processing_status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access",
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleOpenDialog(params.row, true)}
        />,
      ],
    },
  ];

  return (
    <div>
      <Box sx={{ mt: "30px", display: 'flex', gap: 2 }}>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<UploadFileIcon />}
          onClick={() => handleOpenDialog()}
        >
          Add New Document
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
          startIcon={<DeleteIcon />}
          onClick={handleDeleteSelected}
          disabled={selectedRows.length === 0}
        >
          Delete Selected
        </Button>
      </Box>
      <Box
        mt="10px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.document_id}
          rows={(data) || []}
          columns={columns}
          checkboxSelection
          onSelectionModelChange={setSelectedRows}
          selectionModel={selectedRows}
          components={{
            ColumnMenu: CustomColumnMenu,
            Toolbar: GridToolbar,
          }}
          onRowClick={(params) => handleOpenDialog(params.row, true)} 
        />
      </Box>
      
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog} 
        maxWidth="lg"
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: theme.palette.secondary[900],
            width: '95%',
            maxWidth: '1400px',
          },
        }}
      >
        <DialogTitle>{isEditing ? 'Edit Document' : 'Add New Document'}</DialogTitle>
        <DialogContent sx={{ padding: '20px 40px' }}>
          {/* Added padding while keeping fields wide */}
          <Box sx={{ px: 2 }}>
            <TextField
            name="title"
            label="Title"
            value={currentDocument.title}
            onChange={handleInputChange}
            fullWidth
            required
            margin="normal"
          />
          <TextField
            name="description"
            label="Description"
            value={currentDocument.description}
            onChange={handleInputChange}
            fullWidth
            multiline
            rows={3}
            margin="normal"
          />
          
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
            <FormControl sx={{ minWidth: 200, flex: '1 1 200px' }}>
              <InputLabel>Document Type</InputLabel>
              <Select
                name="type"
                value={currentDocument.type}
                onChange={handleInputChange}
                label="Document Type"
              >
                {documentTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <TextField
              name="author"
              label="Author"
              value={currentDocument.author}
              onChange={handleInputChange}
              sx={{ flex: '1 1 200px' }}
            />
            
            <FormControl sx={{ minWidth: 200, flex: '1 1 200px' }}>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={currentDocument.status}
                onChange={handleInputChange}
                label="Status"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Archived">Archived</MenuItem>
                <MenuItem value="Draft">Draft</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl sx={{ minWidth: 200, flex: '1 1 200px' }}>
              <InputLabel>Access Level</InputLabel>
              <Select
                name="accessLevel"
                value={currentDocument.accessLevel}
                onChange={handleInputChange}
                label="Access Level"
              >
                <MenuItem value="Private">Private</MenuItem>
                <MenuItem value="Team">Team</MenuItem>
                <MenuItem value="Public">Public</MenuItem>
              </Select>
            </FormControl>
          </Box>
          
          {/* File Upload Section (replacing Document Link) */}
          <Box sx={{ mt: 3 }}>
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                backgroundColor: theme.palette.secondary[800],
                border: `1px solid ${theme.palette.secondary[600]}`,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <CloudUploadIcon color="primary" />
                <Typography variant="h6">Upload Document</Typography>
              </Box>
              
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.txt,.html,.htm"
              />
              
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<UploadFileIcon />}
                  onClick={() => fileInputRef.current?.click()}
                >
                  Choose File
                </Button>
                
                <Typography>
                  {currentDocument.fileName ? currentDocument.fileName : 'No file selected'}
                </Typography>
              </Stack>
            </Paper>
          </Box>
          
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Tags</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
              <TextField
                label="Add Tag"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                onKeyPress={handleTagInputKeyPress}
                sx={{ flex: 1 }}
              />
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleAddTag}
                disabled={!newTag.trim()}
              >
                Add
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {currentDocument.tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  onDelete={() => handleDeleteTag(tag)}
                />
              ))}
            </Box>
          </Box>
          
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">Additional Information</Typography>
            <TextField
              name="sourceSystem"
              label="Source System"
              value={currentDocument.sourceSystem}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, my: 2 }}>
                <DatePicker
                  label="Created Date"
                  value={currentDocument.createdDate}
                  onChange={(date) => handleDateChange('createdDate', date)}
                  slotProps={{ textField: { fullWidth: true, sx: { flex: '1 1 200px' } } }}
                />
                <DatePicker
                  label="Last Modified Date"
                  value={currentDocument.lastModified}
                  onChange={(date) => handleDateChange('lastModified', date)}
                  slotProps={{ textField: { fullWidth: true, sx: { flex: '1 1 200px' } } }}
                />
              </Box>
            </LocalizationProvider>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: '16px 40px' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleSaveDocument} 
            color="primary" 
            disabled={!currentDocument.title}
          >
            {isEditing ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DocumentManagementGrid;