import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Users",
    "Dashboard",
    "Logs",
    "ToolUsage",
    "ToolUsers",
    "ToolInfo",
    "Role",
    "Documents",
    "RagSolutions",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `user/user/${id}`,
      providesTags: ["User"],
    }),
    getRole: build.query({
      query: (id) => `user/role/${id}`,
      providesTags: ["Role"],
    }),
    getUsers: build.query({
      query: () => "user/users",
      providesTags: ["Users"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
    getLogs: build.query({
      query: () => "logs/logs",
      providesTags: ["Logs"],
    }),
    getToolUsage: build.query({
      query: () => "logs/toolusage",
      providesTags: ["ToolUsage"],
    }),
    getToolUser: build.query({
      query: () => "user/toolusers",
      providesTags: ["ToolUsers"],
    }),
    getToolsForUser: build.query({
      query: (userId) => `user/tooluser/${userId}`,
      providesTags: ['ToolUsers'],
    }),
    updateToolsForUser: build.mutation({
      query: ({ userId, toolIds }) => ({
        url: `user/tooluser/${userId}`,
        method: 'PUT',
        body: { toolIds },
      }),
      invalidatesTags: ['ToolUsers'],
    }),
    getToolInfo: build.query({
      query: () => "tool/toolinfo",
      providesTags: ["ToolInfo"],
    }),
    updateRole: build.mutation({
      query: ({userId, newRole}) => ({
        url: `user/modrole/${userId}`,
        method: "PUT",
        body: {role: newRole},
      }),
      invalidatesTags: ["Role"],
    }),
    addTool :build.mutation({
      query: (data) => ({
        url: `tool/addtool`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ToolInfo"],
    }),
    updateTool :build.mutation({
      query: ({id, toolData}) => ({
        url: `tool/updatetool/${id}`,
        method: "PUT",
        body: {toolData: toolData},
      }),
      invalidatesTags: ["ToolInfo"],
    }),
    deleteTools :build.mutation({
      query: (data) => ({
        url: `tool/deletetool`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["ToolInfo"],
    }),
    // Document endpoints
    getDocuments: build.query({
      query: () => "personal/documents",
      providesTags: ["Documents"],
    }),
    getDocumentById: build.query({
      query: (id) => `personal/documents/${id}`,
      providesTags: (result, error, id) => [{ type: "Documents", id }],
    }),
    addDocument: build.mutation({
      query: (document) => ({
        url: "personal/documents",
        method: "POST",
        body: document,
      }),
      invalidatesTags: ["Documents"],
    }),
    updateDocument: build.mutation({
      query: ({ id, documentData }) => ({
        url: `personal/documents/${id}`,
        method: "PATCH",
        body: documentData,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Documents", id },
        "Documents",
      ],
    }),
    deleteDocuments: build.mutation({
      query: (ids) => ({
        url: "personal/documents/delete",
        method: "POST",
        body: { ids },
      }),
      invalidatesTags: ["Documents"],
    }),

    // RAG Solution endpoints
    getRagSolutions: build.query({
      query: () => "personal/rag-solutions",
      providesTags: ["RagSolutions"],
    }),
    getRagSolutionById: build.query({
      query: (id) => `personal/rag-solutions/${id}`,
      providesTags: (result, error, id) => [{ type: "RagSolutions", id }],
    }),
    addRagSolution: build.mutation({
      query: (ragSolution) => ({
        url: "personal/rag-solutions",
        method: "POST",
        body: ragSolution,
      }),
      invalidatesTags: ["RagSolutions"],
    }),
    updateRagSolution: build.mutation({
      query: ({ id, ragSolutionData }) => ({
        url: `personal/rag-solutions/${id}`,
        method: "PATCH",
        body: ragSolutionData,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "RagSolutions", id },
        "RagSolutions",
      ],
    }),
    deleteRagSolutions: build.mutation({
      query: (ids) => ({
        url: "personal/rag-solutions/delete",
        method: "POST",
        body: { ids },
      }),
      invalidatesTags: ["RagSolutions"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useGetDashboardQuery,
  useGetLogsQuery,
  useGetToolUsageQuery,
  useGetToolUserQuery,
  useGetToolInfoQuery,
  useGetRoleQuery,
  useUpdateRoleMutation,
  useAddToolMutation,
  useUpdateToolMutation,
  useDeleteToolsMutation,
  useGetToolsForUserQuery,
  useUpdateToolsForUserMutation,
  // Document hooks
  useGetDocumentsQuery,
  useGetDocumentByIdQuery,
  useAddDocumentMutation,
  useUpdateDocumentMutation,
  useDeleteDocumentsMutation,
  
  // RAG Solution hooks
  useGetRagSolutionsQuery,
  useGetRagSolutionByIdQuery,
  useAddRagSolutionMutation,
  useUpdateRagSolutionMutation,
  useDeleteRagSolutionsMutation,
} = api;
