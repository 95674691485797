import React, { useState } from "react";
import { Box, useTheme, Button, Tabs, Tab, Typography } from "@mui/material";
import { 
  useGetDocumentsQuery, 
  useAddDocumentMutation, 
  useDeleteDocumentsMutation, 
  useUpdateDocumentMutation,
  useGetRagSolutionsQuery,
  useAddRagSolutionMutation,
  useDeleteRagSolutionsMutation,
  useUpdateRagSolutionMutation
} from "state/api";
import Header from "components/Header";
import FlexBetween from "components/FlexBetween";
import { DownloadOutlined } from "@mui/icons-material";
import DocumentManagementGrid from "components/DocumentManagementGrid";
import RagSolutionManagementGrid from "components/RagSolutionManagementGrid";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const MyStuff = () => {
  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);

  // Document data and mutations
  const { 
    data: documentData, 
    isLoading: isDocumentsLoading 
  } = useGetDocumentsQuery();
  const [addDocument] = useAddDocumentMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [deleteDocuments] = useDeleteDocumentsMutation();

  // RAG Solution data and mutations
  const { 
    data: ragSolutionData, 
    isLoading: isRagSolutionsLoading 
  } = useGetRagSolutionsQuery();
  const [addRagSolution] = useAddRagSolutionMutation();
  const [updateRagSolution] = useUpdateRagSolutionMutation();
  const [deleteRagSolutions] = useDeleteRagSolutionsMutation();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Document handlers
  const handleAddDocument = async (newDocument) => {
    try {
      await addDocument(newDocument).unwrap();
    } catch (error) {
      console.error('Failed to add document:', error);
    }
  };

  const handleUpdateDocument = async (updatedDocument) => {
    try {
      await updateDocument({
        id: updatedDocument.id, 
        documentData: updatedDocument
      }).unwrap();
    } catch (error) {
      console.error('Failed to update document:', error);
    }
  };

  const handleDeleteDocuments = async (documentIds) => {
    try {
      await deleteDocuments(documentIds).unwrap();
    } catch (error) {
      console.error('Failed to delete documents:', error);
    }
  };

  // RAG Solution handlers
  const handleAddRagSolution = async (newRagSolution) => {
    try {
      await addRagSolution(newRagSolution).unwrap();
    } catch (error) {
      console.error('Failed to add RAG solution:', error);
    }
  };

  const handleUpdateRagSolution = async (updatedRagSolution) => {
    try {
      await updateRagSolution({
        id: updatedRagSolution.id, 
        ragSolutionData: updatedRagSolution
      }).unwrap();
    } catch (error) {
      console.error('Failed to update RAG solution:', error);
    }
  };

  const handleDeleteRagSolutions = async (ragSolutionIds) => {
    try {
      await deleteRagSolutions(ragSolutionIds).unwrap();
    } catch (error) {
      console.error('Failed to delete RAG solutions:', error);
    }
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="My Stuff" subtitle="Manage your Documents and RAG solutions" />
        <Box>
        </Box>
      </FlexBetween>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="Documents" />
          <Tab label="RAG Solutions" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <DocumentManagementGrid
          data={documentData}
          isLoading={isDocumentsLoading}
          onAddDocument={handleAddDocument}
          onUpdateDocument={handleUpdateDocument}
          onDeleteDocuments={handleDeleteDocuments}
        />
      </TabPanel>
      
      <TabPanel value={tabValue} index={1}>
        <RagSolutionManagementGrid
          data={ragSolutionData}
          isLoading={isRagSolutionsLoading}
          onAddRagSolution={handleAddRagSolution}
          onUpdateRagSolution={handleUpdateRagSolution}
          onDeleteRagSolutions={handleDeleteRagSolutions}
        />
      </TabPanel>
    </Box>
  );
};

export default MyStuff;