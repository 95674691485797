import React, { useState } from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogActions,
  IconButton, 
  Box, 
  Stepper, 
  Step, 
  StepLabel,
  Button,
  DialogTitle,
  Typography
} from '@mui/material';
import { 
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon 
} from '@mui/icons-material';

const RagCreationWizard = ({ open, onClose, onCreateRagSolution }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    // Basic information
    name: '',
    description: '',
    environment: 'Development',
    tagsInput: '',
    
    // Document processing
    chunkingStrategy: 'automatic',
    chunkSize: 512,
    chunkOverlap: 50,
    cleanupOptions: ['removeExtraWhitespace', 'removeHeaders'],
    
    // Vector database
    databaseStrategy: 'automatic',
    indexType: 'FAISS',
    embeddings: 'text-embedding-3-small',
    retrievalConfig: {
      topK: 3,
      similarityThreshold: 0.7,
      useReranking: false,
    },
    
    // Language model
    modelStrategy: 'automatic',
    model: 'OpenAI',
    modelVersion: 'gpt-4-turbo',
    temperature: 0.7,
    maxTokens: 1000,
    systemPrompt: 'You are a helpful assistant. Use the following retrieved information to answer the user\'s question.\n\nRetrieved information: {context}\n\nUser question: {question}'
  });

  const steps = ['Basic Info', 'Document Processing', 'Vector Database', 'Language Model', 'Review & Deploy'];

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: type === 'checkbox' ? checked : value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };
  
  const handleRadioChange = (section, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: value
    }));
  };
  
  const handleCheckboxChange = (option) => {
    const currentOptions = [...formData.cleanupOptions];
    if (currentOptions.includes(option)) {
      setFormData(prev => ({
        ...prev,
        cleanupOptions: currentOptions.filter(item => item !== option)
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        cleanupOptions: [...currentOptions, option]
      }));
    }
  };
  
  const handleSliderChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: parseInt(value)
    }));
  };

  const handleSubmit = () => {
    // Process the tags from comma-separated string to array
    const tags = formData.tagsInput
      .split(',')
      .map(tag => tag.trim())
      .filter(tag => tag !== '');
      
    // Create the RAG solution object
    const ragSolution = {
      ...formData,
      tags,
      createdDate: new Date().toISOString().split('T')[0],
      lastModified: new Date().toISOString().split('T')[0],
    };
    
    // Pass the created RAG solution to the parent component
    onCreateRagSolution(ragSolution);
    
    // Close the wizard
    onClose();
  };

  // Check if current step is complete and next button can be enabled
  const isStepComplete = () => {
    switch (activeStep) {
      case 0:
        return !!formData.name;
      case 1:
        return true; // Document processing is always valid
      case 2:
        return true; // Vector database is always valid
      case 3:
        return true; // Language model is always valid
      case 4:
        return true; // Review step is always valid
      default:
        return false;
    }
  };

  // Render the basic information step
  const renderBasicInfoStep = () => (
    <div className="wizard-step-content">
      <Typography className="wizard-section-title">
        Basic Information
      </Typography>
      
      <div className="wizard-field">
        <label htmlFor="name" className="wizard-field-label">RAG Solution Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter a descriptive name"
          className="wizard-input"
          required
        />
      </div>
      
      <div className="wizard-field">
        <label htmlFor="description" className="wizard-field-label">Description</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          rows="3"
          placeholder="What will this RAG solution be used for?"
          className="wizard-textarea"
        />
      </div>
      
      <div className="wizard-field">
        <label htmlFor="environment" className="wizard-field-label">Environment</label>
        <select
          id="environment"
          name="environment"
          value={formData.environment}
          onChange={handleInputChange}
          className="wizard-select"
        >
          <option value="Development">Development</option>
          <option value="Testing">Testing</option>
          <option value="Staging">Staging</option>
          <option value="Production">Production</option>
        </select>
      </div>
      
      <div className="wizard-field">
        <label htmlFor="tagsInput" className="wizard-field-label">Tags (comma separated)</label>
        <input
          type="text"
          id="tagsInput"
          name="tagsInput"
          value={formData.tagsInput}
          onChange={handleInputChange}
          placeholder="e.g., support, documentation, internal"
          className="wizard-input"
        />
        <p className="wizard-helper-text">
          Tags help you organize and find your RAG solutions later
        </p>
        
        {formData.tagsInput && (
          <div className="wizard-tags-container">
            {formData.tagsInput.split(',').map((tag, index) => (
              tag.trim() && (
                <span key={index} className="wizard-tag">
                  {tag.trim()}
                </span>
              )
            ))}
          </div>
        )}
      </div>
    </div>
  );
  
  // Render the document processing step
  const renderDocumentProcessingStep = () => (
    <div className="wizard-step-content">
      <Typography className="wizard-section-title">
        Document Preparation
      </Typography>
      
      <Typography sx={{ color: '#c5cdd8', mb: 3 }}>
        Let's prepare your documents for the AI to understand them better. We'll break them into 
        manageable pieces and clean them up.
      </Typography>
      
      <Typography sx={{ fontWeight: 500, color: '#fff', mb: 2 }}>
        How should we divide your documents?
      </Typography>
      
      <div className="wizard-radio-group">
        <div 
          className={`wizard-radio-option ${formData.chunkingStrategy === 'automatic' ? 'selected' : ''}`}
          onClick={() => handleRadioChange('chunkingStrategy', 'automatic')}
        >
          <div className="wizard-radio-button"></div>
          <div className="wizard-radio-content">
            <div className="wizard-radio-label">Automatic (Recommended)</div>
            <div className="wizard-radio-description">
              We'll intelligently split your documents by paragraphs and sections.
            </div>
          </div>
        </div>
        
        <div 
          className={`wizard-radio-option ${formData.chunkingStrategy === 'custom' ? 'selected' : ''}`}
          onClick={() => handleRadioChange('chunkingStrategy', 'custom')}
        >
          <div className="wizard-radio-button"></div>
          <div className="wizard-radio-content">
            <div className="wizard-radio-label">Custom</div>
            <div className="wizard-radio-description">
              Choose exactly how to split your documents.
            </div>
          </div>
        </div>
      </div>
      
      {formData.chunkingStrategy === 'custom' && (
        <div className="wizard-chunking-container">
          <div className="wizard-slider-container">
            <div className="wizard-slider-label">Chunk Size: {formData.chunkSize} tokens</div>
            <div className="wizard-slider-value">{formData.chunkSize} tokens</div>
            <input
              type="range"
              name="chunkSize"
              className="wizard-slider"
              min="128"
              max="1024"
              step="64"
              value={formData.chunkSize}
              onChange={handleSliderChange}
            />
            <div className="wizard-slider-labels">
              <div className="wizard-slider-label-text">Small</div>
              <div className="wizard-slider-label-text">Medium</div>
              <div className="wizard-slider-label-text">Large</div>
            </div>
            <div className="wizard-slider-description">
              Controls how much text the AI sees at once. Smaller chunks are better for factual questions, 
              larger chunks provide more context.
            </div>
          </div>
          
          <div className="wizard-slider-container">
            <div className="wizard-slider-label">Chunk Overlap: {formData.chunkOverlap} tokens</div>
            <div className="wizard-slider-value">{formData.chunkOverlap} tokens</div>
            <input
              type="range"
              name="chunkOverlap"
              className="wizard-slider"
              min="0"
              max="200"
              step="10"
              value={formData.chunkOverlap}
              onChange={handleSliderChange}
            />
            <div className="wizard-slider-labels">
              <div className="wizard-slider-label-text">None</div>
              <div className="wizard-slider-label-text">Medium</div>
              <div className="wizard-slider-label-text">Large</div>
            </div>
            <div className="wizard-slider-description">
              Determines how much text is repeated between chunks. More overlap helps maintain context 
              between chunks but uses more storage.
            </div>
          </div>
        </div>
      )}
      
      <Typography sx={{ fontWeight: 500, color: '#fff', mb: 2, mt: 3 }}>
        Document Cleanup
      </Typography>
      
      <Typography sx={{ color: '#9aa5b5', mb: 2 }}>
        Select what you'd like to remove or fix in your documents:
      </Typography>
      
      <div className="wizard-checkbox-group">
        <div 
          className={`wizard-checkbox-option ${formData.cleanupOptions.includes('removeExtraWhitespace') ? 'selected' : ''}`}
          onClick={() => handleCheckboxChange('removeExtraWhitespace')}
        >
          <div className="wizard-checkbox-button"></div>
          <div className="wizard-checkbox-label">Remove extra spaces and line breaks</div>
        </div>
        
        <div 
          className={`wizard-checkbox-option ${formData.cleanupOptions.includes('removeHeaders') ? 'selected' : ''}`}
          onClick={() => handleCheckboxChange('removeHeaders')}
        >
          <div className="wizard-checkbox-button"></div>
          <div className="wizard-checkbox-label">Remove headers and footers</div>
        </div>
        
        <div 
          className={`wizard-checkbox-option ${formData.cleanupOptions.includes('fixEncoding') ? 'selected' : ''}`}
          onClick={() => handleCheckboxChange('fixEncoding')}
        >
          <div className="wizard-checkbox-button"></div>
          <div className="wizard-checkbox-label">Fix special characters</div>
        </div>
        
        <div 
          className={`wizard-checkbox-option ${formData.cleanupOptions.includes('removeUrls') ? 'selected' : ''}`}
          onClick={() => handleCheckboxChange('removeUrls')}
        >
          <div className="wizard-checkbox-button"></div>
          <div className="wizard-checkbox-label">Remove URLs and email addresses</div>
        </div>
      </div>
    </div>
  );
  
  // Render the vector database step
  const renderVectorDatabaseStep = () => (
    <div className="wizard-step-content">
      <Typography className="wizard-section-title">
        Vector Database Configuration
      </Typography>
      
      <Typography sx={{ color: '#c5cdd8', mb: 3 }}>
        Choose how your document chunks will be stored and retrieved. This affects search speed and accuracy.
      </Typography>
      
      <div className="wizard-radio-group">
        <div 
          className={`wizard-radio-option ${formData.databaseStrategy === 'automatic' ? 'selected' : ''}`}
          onClick={() => handleRadioChange('databaseStrategy', 'automatic')}
        >
          <div className="wizard-radio-button"></div>
          <div className="wizard-radio-content">
            <div className="wizard-radio-label">Automatic (Recommended)</div>
            <div className="wizard-radio-description">
              We'll choose the best vector database and embedding model for your use case.
            </div>
          </div>
        </div>
        
        <div 
          className={`wizard-radio-option ${formData.databaseStrategy === 'custom' ? 'selected' : ''}`}
          onClick={() => handleRadioChange('databaseStrategy', 'custom')}
        >
          <div className="wizard-radio-button"></div>
          <div className="wizard-radio-content">
            <div className="wizard-radio-label">Custom</div>
            <div className="wizard-radio-description">
              Choose your preferred vector database, embedding model, and search parameters.
            </div>
          </div>
        </div>
      </div>
      
      {formData.databaseStrategy === 'custom' && (
        <div className="wizard-chunking-container">
          <div className="wizard-field">
            <label className="wizard-field-label">Vector Index Type</label>
            <select
              name="indexType"
              value={formData.indexType}
              onChange={handleInputChange}
              className="wizard-select"
            >
              <option value="FAISS">FAISS (Local)</option>
              <option value="Pinecone">Pinecone (Cloud)</option>
              <option value="Elasticsearch">Elasticsearch</option>
              <option value="Weaviate">Weaviate</option>
              <option value="Chroma">Chroma</option>
              <option value="Milvus">Milvus</option>
              <option value="Pgvector">Pgvector (PostgreSQL)</option>
            </select>
            <div className="wizard-slider-description">
              The vector database that will store your document embeddings.
            </div>
          </div>
          
          <div className="wizard-field">
            <label className="wizard-field-label">Embedding Model</label>
            <select
              name="embeddings"
              value={formData.embeddings}
              onChange={handleInputChange}
              className="wizard-select"
            >
              <option value="text-embedding-3-large">OpenAI text-embedding-3-large</option>
              <option value="text-embedding-3-small">OpenAI text-embedding-3-small</option>
              <option value="sentence-transformers/all-mpnet-base-v2">SBERT all-mpnet-base-v2</option>
              <option value="cohere-embed-english-v3.0">Cohere embed-english-v3.0</option>
            </select>
            <div className="wizard-slider-description">
              The model that converts text into vector embeddings for semantic search.
            </div>
          </div>
          
          <Typography sx={{ fontWeight: 500, color: '#fff', mt: 3, mb: 2 }}>
            Retrieval Settings
          </Typography>
          
          <div className="wizard-slider-container">
            <div className="wizard-slider-label">
              Similarity Threshold: {formData.retrievalConfig.similarityThreshold}
            </div>
            <input
              type="range"
              name="retrievalConfig.similarityThreshold"
              className="wizard-slider"
              min="0"
              max="1"
              step="0.05"
              value={formData.retrievalConfig.similarityThreshold}
              onChange={handleInputChange}
            />
            <div className="wizard-slider-description">
              Minimum similarity score required to include a result. Higher values return only more relevant results.
            </div>
          </div>
          
          <div className="wizard-slider-container">
            <div className="wizard-slider-label">
              Top K Results: {formData.retrievalConfig.topK}
            </div>
            <input
              type="range"
              name="retrievalConfig.topK"
              className="wizard-slider"
              min="1"
              max="10"
              step="1"
              value={formData.retrievalConfig.topK}
              onChange={handleInputChange}
            />
            <div className="wizard-slider-description">
              Maximum number of document chunks to retrieve for each query.
            </div>
          </div>
          
          <div 
            className={`wizard-checkbox-option ${formData.retrievalConfig.useReranking ? 'selected' : ''}`}
            onClick={() => handleInputChange({
              target: {
                name: 'retrievalConfig.useReranking',
                type: 'checkbox',
                checked: !formData.retrievalConfig.useReranking
              }
            })}
          >
            <div className="wizard-checkbox-button"></div>
            <div className="wizard-checkbox-label">Use result reranking</div>
          </div>
        </div>
      )}
    </div>
  );
  
  // Render the language model step
  const renderLanguageModelStep = () => (
    <div className="wizard-step-content">
      <Typography className="wizard-section-title">
        Language Model Configuration
      </Typography>
      
      <Typography sx={{ color: '#c5cdd8', mb: 3 }}>
        Select the language model that will generate responses using your retrieved documents.
      </Typography>
      
      <div className="wizard-radio-group">
        <div 
          className={`wizard-radio-option ${formData.modelStrategy === 'automatic' ? 'selected' : ''}`}
          onClick={() => handleRadioChange('modelStrategy', 'automatic')}
        >
          <div className="wizard-radio-button"></div>
          <div className="wizard-radio-content">
            <div className="wizard-radio-label">Automatic (Recommended)</div>
            <div className="wizard-radio-description">
              We'll choose a well-balanced model with good performance and reasonable cost.
            </div>
          </div>
        </div>
        
        <div 
          className={`wizard-radio-option ${formData.modelStrategy === 'custom' ? 'selected' : ''}`}
          onClick={() => handleRadioChange('modelStrategy', 'custom')}
        >
          <div className="wizard-radio-button"></div>
          <div className="wizard-radio-content">
            <div className="wizard-radio-label">Custom</div>
            <div className="wizard-radio-description">
              Choose the specific model, parameters, and system prompt to use.
            </div>
          </div>
        </div>
      </div>
      
      {formData.modelStrategy === 'custom' && (
        <div className="wizard-chunking-container">
          <div className="wizard-field">
            <label className="wizard-field-label">Model Provider</label>
            <select
              name="model"
              value={formData.model}
              onChange={handleInputChange}
              className="wizard-select"
            >
              <option value="OpenAI">OpenAI</option>
              <option value="Anthropic">Anthropic</option>
              <option value="Cohere">Cohere</option>
              <option value="LLaMA">LLaMA (Local)</option>
              <option value="Mistral">Mistral</option>
            </select>
          </div>
          
          <div className="wizard-field">
            <label className="wizard-field-label">Model Version</label>
            <select
              name="modelVersion"
              value={formData.modelVersion}
              onChange={handleInputChange}
              className="wizard-select"
            >
              {formData.model === 'OpenAI' && (
                <>
                  <option value="gpt-4-turbo">GPT-4 Turbo</option>
                  <option value="gpt-4o">GPT-4o</option>
                  <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                </>
              )}
              {formData.model === 'Anthropic' && (
                <>
                  <option value="claude-3-opus">Claude 3 Opus</option>
                  <option value="claude-3-sonnet">Claude 3 Sonnet</option>
                  <option value="claude-3-haiku">Claude 3 Haiku</option>
                </>
              )}
              {formData.model === 'Cohere' && (
                <>
                  <option value="command-r">Command R</option>
                  <option value="command">Command</option>
                </>
              )}
              {formData.model === 'Mistral' && (
                <>
                  <option value="mistral-large">Mistral Large</option>
                  <option value="mistral-medium">Mistral Medium</option>
                  <option value="mistral-small">Mistral Small</option>
                </>
              )}
              {formData.model === 'LLaMA' && (
                <>
                  <option value="llama-3-70b">LLaMA 3 70B</option>
                  <option value="llama-3-8b">LLaMA 3 8B</option>
                </>
              )}
            </select>
          </div>
          
          <Typography sx={{ fontWeight: 500, color: '#fff', mt: 3, mb: 2 }}>
            Generation Parameters
          </Typography>
          
          <div className="wizard-slider-container">
            <div className="wizard-slider-label">Temperature: {formData.temperature}</div>
            <input
              type="range"
              name="temperature"
              className="wizard-slider"
              min="0"
              max="1"
              step="0.1"
              value={formData.temperature}
              onChange={handleInputChange}
            />
            <div className="wizard-slider-labels">
              <div className="wizard-slider-label-text">More Precise</div>
              <div className="wizard-slider-label-text">Balanced</div>
              <div className="wizard-slider-label-text">More Creative</div>
            </div>
          </div>
          
          <div className="wizard-slider-container">
            <div className="wizard-slider-label">Max Tokens: {formData.maxTokens}</div>
            <input
              type="range"
              name="maxTokens"
              className="wizard-slider"
              min="100"
              max="4000"
              step="100"
              value={formData.maxTokens}
              onChange={handleInputChange}
            />
          </div>
          
          <div className="wizard-field">
            <label className="wizard-field-label">System Prompt</label>
            <textarea
              name="systemPrompt"
              value={formData.systemPrompt}
              onChange={handleInputChange}
              rows={6}
              className="wizard-textarea"
            />
            <div className="wizard-slider-description">
              Instructions given to the model that affect how it responds. Use {'{context}'} to reference retrieved documents and {'{question}'} for the user's question.
            </div>
          </div>
        </div>
      )}
    </div>
  );
  
  // Render the review step
  const renderReviewStep = () => (
    <div className="wizard-step-content">
      <Typography className="wizard-section-title">
        Review & Deploy
      </Typography>
      
      <Typography sx={{ color: '#c5cdd8', mb: 3 }}>
        Review your RAG solution settings before deployment.
      </Typography>
      
      <Box sx={{ 
        backgroundColor: '#2c3846', 
        borderRadius: '8px',
        padding: '20px',
        mb: 3
      }}>
        <Typography variant="h6" sx={{ color: '#fff', mb: 2 }}>
          Solution Summary
        </Typography>
        
        <Box sx={{ display: 'flex', mb: 1 }}>
          <Box sx={{ width: '150px', color: '#9aa5b5', fontWeight: 500 }}>Name:</Box>
          <Box sx={{ flex: 1, color: '#e0e0e0' }}>{formData.name}</Box>
        </Box>
        
        <Box sx={{ display: 'flex', mb: 1 }}>
          <Box sx={{ width: '150px', color: '#9aa5b5', fontWeight: 500 }}>Environment:</Box>
          <Box sx={{ flex: 1, color: '#e0e0e0' }}>{formData.environment}</Box>
        </Box>
        
        <Box sx={{ display: 'flex', mb: 1 }}>
          <Box sx={{ width: '150px', color: '#9aa5b5', fontWeight: 500 }}>Vector DB:</Box>
          <Box sx={{ flex: 1, color: '#e0e0e0' }}>{formData.indexType}</Box>
        </Box>
        
        <Box sx={{ display: 'flex', mb: 1 }}>
          <Box sx={{ width: '150px', color: '#9aa5b5', fontWeight: 500 }}>Model:</Box>
          <Box sx={{ flex: 1, color: '#e0e0e0' }}>{formData.model} ({formData.modelVersion})</Box>
        </Box>
        
        {formData.description && (
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ width: '150px', color: '#9aa5b5', fontWeight: 500 }}>Description:</Box>
            <Box sx={{ flex: 1, color: '#e0e0e0' }}>{formData.description}</Box>
          </Box>
        )}
        
        {formData.tagsInput && (
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Box sx={{ width: '150px', color: '#9aa5b5', fontWeight: 500, mt: 1 }}>Tags:</Box>
            <Box sx={{ flex: 1 }}>
              <div className="wizard-tags-container">
                {formData.tagsInput.split(',').map((tag, index) => (
                  tag.trim() && (
                    <span key={index} className="wizard-tag">
                      {tag.trim()}
                    </span>
                  )
                ))}
              </div>
            </Box>
          </Box>
        )}
      </Box>
      
      <Box sx={{ 
        backgroundColor: 'rgba(255, 210, 51, 0.1)', 
        borderRadius: '8px',
        padding: '20px',
        border: '1px solid rgba(255, 210, 51, 0.3)'
      }}>
        <Typography sx={{ color: '#ffd233', fontWeight: 600, mb: 1 }}>
          Ready to deploy?
        </Typography>
        <Typography sx={{ color: '#c5cdd8' }}>
          Your RAG solution will be created with the settings above. You'll be able to test
          and refine it after deployment.
        </Typography>
      </Box>
    </div>
  );

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Create New RAG Solution</Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      
      <Box sx={{ backgroundColor: '#2c3846', padding: '20px', borderBottom: '1px solid #3a4655' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      
      <DialogContent>
        {activeStep === 0 && renderBasicInfoStep()}
        {activeStep === 1 && renderDocumentProcessingStep()}
        {activeStep === 2 && renderVectorDatabaseStep()}
        {activeStep === 3 && renderLanguageModelStep()}
        {activeStep === 4 && renderReviewStep()}
      </DialogContent>
      
      <DialogActions>
        <Button 
          className="wizard-back-button"
          onClick={handleBack}
          disabled={activeStep === 0}
        >
          Back
        </Button>
        
        {activeStep === steps.length - 1 ? (
          <Button 
            className="wizard-next-button"
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: '#60d394' }}
            startIcon={<CheckCircleIcon />}
          >
            Create RAG Solution
          </Button>
        ) : (
          <Button 
            className="wizard-next-button"
            onClick={handleNext}
            variant="contained"
            disabled={!isStepComplete()}
          >
            Next: {steps[activeStep + 1]}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RagCreationWizard;