import React, { useState, useCallback } from 'react';
import { 
  DataGrid, 
  GridToolbar,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  useTheme,
  Typography,
  Paper,
  IconButton,
  Chip,
  Rating,
  Slider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { 
  Add as AddIcon, 
  Delete as DeleteIcon, 
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  Code as CodeIcon,
  Storage as StorageIcon,
} from '@mui/icons-material';
import CustomColumnMenu from "components/DataGridCustomColumnMenu";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import RagCreationWizard from './RagCreationWizard';

const modelTypes = [
  'OpenAI',
  'Anthropic',
  'Cohere',
  'LLaMA',
  'Falcon',
  'Mistral',
  'Other',
];

const indexTypes = [
  'FAISS',
  'Pinecone',
  'Elasticsearch',
  'Weaviate',
  'Chroma',
  'Milvus',
  'Pgvector',
  'Other',
];

const RagSolutionManagementGrid = ({ data, isLoading, onAddRagSolution, onUpdateRagSolution, onDeleteRagSolutions }) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [openWizard, setOpenWizard] = useState(false);
  const [currentRagSolution, setCurrentRagSolution] = useState({
    name: '', 
    description: '', 
    model: '', 
    modelVersion: '',
    indexType: '',
    chunkSize: 512,
    chunkOverlap: 50,
    documentSources: [],
    createdDate: null,
    lastModified: null,
    accuracy: 0,
    relevance: 0,
    latency: 0,
    status: 'Active',
    environment: 'Development',
    retrievalConfig: {
      topK: 3,
      similarityThreshold: 0.7,
      useReranking: false,
    },
    embeddings: '',
    tags: [],
  });
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedSection, setExpandedSection] = useState('general');
  const [newTag, setNewTag] = useState('');
  const [newDocumentSource, setNewDocumentSource] = useState('');
  
  const handleOpenDialog = useCallback((ragSolution = {
    name: '', 
    description: '', 
    model: '', 
    modelVersion: '',
    indexType: '',
    chunkSize: 512,
    chunkOverlap: 50,
    documentSources: [],
    createdDate: null,
    lastModified: null,
    accuracy: 0,
    relevance: 0,
    latency: 0,
    status: 'Active',
    environment: 'Development',
    retrievalConfig: {
      topK: 3,
      similarityThreshold: 0.7,
      useReranking: false,
    },
    embeddings: '',
    tags: [],
  }, edit = false) => {
    setCurrentRagSolution({
      ...ragSolution,
      createdDate: ragSolution.createdDate ? parseISO(ragSolution.createdDate) : null,
      lastModified: ragSolution.lastModified ? parseISO(ragSolution.lastModified) : null,
      documentSources: Array.isArray(ragSolution.documentSources) ? ragSolution.documentSources : [],
      tags: Array.isArray(ragSolution.tags) ? ragSolution.tags : [],
      retrievalConfig: ragSolution.retrievalConfig || {
        topK: 3,
        similarityThreshold: 0.7,
        useReranking: false,
      },
    });
    setIsEditing(edit);
    setOpenDialog(true);
    setExpandedSection('general');
  }, []);

  const handleOpenWizard = useCallback(() => {
    setOpenWizard(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setCurrentRagSolution({
      name: '', 
      description: '', 
      model: '', 
      modelVersion: '',
      indexType: '',
      chunkSize: 512,
      chunkOverlap: 50,
      documentSources: [],
      createdDate: null,
      lastModified: null,
      accuracy: 0,
      relevance: 0,
      latency: 0,
      status: 'Active',
      environment: 'Development',
      retrievalConfig: {
        topK: 3,
        similarityThreshold: 0.7,
        useReranking: false,
      },
      embeddings: '',
      tags: [],
    });
    setIsEditing(false);
    setNewTag('');
    setNewDocumentSource('');
  }, []);

  const handleCloseWizard = useCallback(() => {
    setOpenWizard(false);
  }, []);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setCurrentRagSolution((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleDateChange = useCallback((name, date) => {
    setCurrentRagSolution((prev) => ({
      ...prev,
      [name]: date,
    }));
  }, []);

  const handleSliderChange = useCallback((name, value) => {
    setCurrentRagSolution((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleRetrievalConfigChange = useCallback((name, value) => {
    setCurrentRagSolution((prev) => ({
      ...prev,
      retrievalConfig: {
        ...prev.retrievalConfig,
        [name]: value,
      }
    }));
  }, []);

  const handleCheckboxChange = useCallback((name, checked) => {
    setCurrentRagSolution((prev) => ({
      ...prev,
      retrievalConfig: {
        ...prev.retrievalConfig,
        [name]: checked,
      }
    }));
  }, []);

  const handleAddTag = useCallback(() => {
    if (newTag.trim() !== '') {
      setCurrentRagSolution((prev) => ({
        ...prev,
        tags: [...prev.tags, newTag.trim()]
      }));
      setNewTag('');
    }
  }, [newTag]);

  const handleDeleteTag = useCallback((tagToDelete) => {
    setCurrentRagSolution((prev) => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToDelete)
    }));
  }, []);

  const handleAddDocumentSource = useCallback(() => {
    if (newDocumentSource.trim() !== '') {
      setCurrentRagSolution((prev) => ({
        ...prev,
        documentSources: [...prev.documentSources, newDocumentSource.trim()]
      }));
      setNewDocumentSource('');
    }
  }, [newDocumentSource]);

  const handleDeleteDocumentSource = useCallback((sourceToDelete) => {
    setCurrentRagSolution((prev) => ({
      ...prev,
      documentSources: prev.documentSources.filter(source => source !== sourceToDelete)
    }));
  }, []);

  const handleSaveRagSolution = useCallback(() => {
    const ragSolutionToSave = {
      ...currentRagSolution,
      createdDate: currentRagSolution.createdDate ? format(currentRagSolution.createdDate, 'yyyy-MM-dd') : null,
      lastModified: currentRagSolution.lastModified ? format(currentRagSolution.lastModified, 'yyyy-MM-dd') : null,
    };
    
    if (isEditing) {
      onUpdateRagSolution(ragSolutionToSave);
    } else {
      onAddRagSolution(ragSolutionToSave);
    }
    handleCloseDialog();
  }, [currentRagSolution, isEditing, onUpdateRagSolution, onAddRagSolution, handleCloseDialog]);

  const handleCreateRagSolution = useCallback((ragSolution) => {
    // Add creation date and other required fields
    const newRagSolution = {
      ...ragSolution,
      createdDate: format(new Date(), 'yyyy-MM-dd'),
      lastModified: format(new Date(), 'yyyy-MM-dd'),
    };
    
    onAddRagSolution(newRagSolution);
  }, [onAddRagSolution]);

  const handleDeleteSelected = useCallback(() => {
    onDeleteRagSolutions(selectedRows);
    setSelectedRows([]);
  }, [selectedRows, onDeleteRagSolutions]);

  const handleKeyPress = useCallback((e, handler) => {
    if (e.key === 'Enter') {
      handler();
      e.preventDefault();
    }
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "model",
      headerName: "Model",
      flex: 1,
    },
    {
      field: "model_version",
      headerName: "Version",
      flex: 1,
    },
    {
      field: "index_type",
      headerName: "Vector Database",
      flex: 1,
    },
    {
        field: "embeddings",
        headerName: "Embeddings Model",
        flex: 1,
      },
    {
      field: "relevance",
      headerName: "User Rating",
      flex: 1,
      renderCell: (params) => (
        <Rating 
          value={params.value / 20} 
          precision={0.5} 
          size="small" 
          readOnly 
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: .5,
    },
    {
      field: "environment",
      headerName: "Environment",
      flex: 1,
    },
    {
      field: "last_modified",
      headerName: "Last Modified",
      flex: 1,
      valueFormatter: (params) => params.value ? format(parseISO(params.value), 'MM/dd/yyyy') : '',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => handleOpenDialog(params.row, true)}
        />,
      ],
    },
  ];

  return (
    <div>
      <Box sx={{ mt: "30px", display: 'flex', gap: 2 }}>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={handleOpenWizard}
        >
          Create New RAG Solution
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
          startIcon={<DeleteIcon />}
          onClick={handleDeleteSelected}
          disabled={selectedRows.length === 0}
        >
          Delete Selected
        </Button>
      </Box>
      <Box
        mt="10px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.solution_id}
          rows={(data) || []}
          columns={columns}
          checkboxSelection
          onSelectionModelChange={setSelectedRows}
          selectionModel={selectedRows}
          components={{
            ColumnMenu: CustomColumnMenu,
            Toolbar: GridToolbar,
          }}
          onRowClick={(params) => handleOpenDialog(params.row, true)} 
        />
      </Box>
      
      {/* Regular dialog for editing */}
      <Dialog 
        open={openDialog} 
        onClose={handleCloseDialog} 
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: theme.palette.secondary[900],
            width: '80%',
            maxWidth: 'none',
          },
        }}
      >
        <DialogTitle>{isEditing ? 'Edit RAG Solution' : 'Create New RAG Solution'}</DialogTitle>
        <DialogContent>
          <Accordion 
            expanded={expandedSection === 'general'} 
            onChange={() => setExpandedSection('general')}
            sx={{ backgroundColor: theme.palette.secondary[800], mb: 2 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">General Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                name="name"
                label="Solution Name"
                value={currentRagSolution.name}
                onChange={handleInputChange}
                fullWidth
                required
                margin="normal"
              />
              <TextField
                name="description"
                label="Description"
                value={currentRagSolution.description}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={3}
                margin="normal"
              />
              
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                <FormControl sx={{ minWidth: 200, flex: '1 1 200px' }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    name="status"
                    value={currentRagSolution.status}
                    onChange={handleInputChange}
                    label="Status"
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                    <MenuItem value="Deprecated">Deprecated</MenuItem>
                    <MenuItem value="In Development">In Development</MenuItem>
                  </Select>
                </FormControl>
                
                <FormControl sx={{ minWidth: 200, flex: '1 1 200px' }}>
                  <InputLabel>Environment</InputLabel>
                  <Select
                    name="environment"
                    value={currentRagSolution.environment}
                    onChange={handleInputChange}
                    label="Environment"
                  >
                    <MenuItem value="Development">Development</MenuItem>
                    <MenuItem value="Testing">Testing</MenuItem>
                    <MenuItem value="Staging">Staging</MenuItem>
                    <MenuItem value="Production">Production</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6">Tags</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                  <TextField
                    label="Add Tag"
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, handleAddTag)}
                    sx={{ flex: 1 }}
                  />
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleAddTag}
                    disabled={!newTag.trim()}
                  >
                    Add
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {currentRagSolution.tags.map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag}
                      onDelete={() => handleDeleteTag(tag)}
                    />
                  ))}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          
          <Accordion 
            expanded={expandedSection === 'model'} 
            onChange={() => setExpandedSection('model')}
            sx={{ backgroundColor: theme.palette.secondary[800], mb: 2 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CodeIcon sx={{ mr: 1 }} />
                <Typography variant="h6">LLM & Embedding Configuration</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <FormControl sx={{ minWidth: 200, flex: '1 1 200px' }}>
                  <InputLabel>Model Provider</InputLabel>
                  <Select
                    name="model"
                    value={currentRagSolution.model}
                    onChange={handleInputChange}
                    label="Model Provider"
                  >
                    {modelTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                
                <TextField
                  name="modelVersion"
                  label="Model Version"
                  value={currentRagSolution.modelVersion}
                  onChange={handleInputChange}
                  sx={{ flex: '1 1 200px' }}
                />
                
                <TextField
                  name="embeddings"
                  label="Embeddings Model"
                  value={currentRagSolution.embeddings}
                  onChange={handleInputChange}
                  sx={{ flex: '1 1 200px' }}
                />
              </Box>

              <Typography variant="subtitle1" sx={{ mt: 3 }}>Performance Metrics</Typography>
              <Box sx={{ px: 2, pt: 2 }}>
                <Typography gutterBottom>Accuracy: {currentRagSolution.accuracy}%</Typography>
                <Slider
                  value={currentRagSolution.accuracy}
                  onChange={(_, value) => handleSliderChange('accuracy', value)}
                  valueLabelDisplay="auto"
                  step={5}
                  marks
                  min={0}
                  max={100}
                />
                
                <Typography gutterBottom>Relevance: {currentRagSolution.relevance}%</Typography>
                <Slider
                  value={currentRagSolution.relevance}
                  onChange={(_, value) => handleSliderChange('relevance', value)}
                  valueLabelDisplay="auto"
                  step={5}
                  marks
                  min={0}
                  max={100}
                />
                
                <Typography gutterBottom>Latency: {currentRagSolution.latency} ms</Typography>
                <Slider
                  value={currentRagSolution.latency}
                  onChange={(_, value) => handleSliderChange('latency', value)}
                  valueLabelDisplay="auto"
                  step={50}
                  marks
                  min={0}
                  max={1000}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
          
          <Accordion 
            expanded={expandedSection === 'vectorstore'} 
            onChange={() => setExpandedSection('vectorstore')}
            sx={{ backgroundColor: theme.palette.secondary[800], mb: 2 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StorageIcon sx={{ mr: 1 }} />
                <Typography variant="h6">Vector Database & Chunking</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <FormControl sx={{ minWidth: 200, flex: '1 1 200px' }}>
                  <InputLabel>Vector Index Type</InputLabel>
                  <Select
                    name="indexType"
                    value={currentRagSolution.indexType}
                    onChange={handleInputChange}
                    label="Vector Index Type"
                  >
                    {indexTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              
              <Typography variant="subtitle1" sx={{ mt: 3 }}>Chunking Configuration</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, mt: 2 }}>
                <Box sx={{ width: '45%' }}>
                  <Typography gutterBottom>Chunk Size: {currentRagSolution.chunkSize} tokens</Typography>
                  <Slider
                    value={currentRagSolution.chunkSize}
                    onChange={(_, value) => handleSliderChange('chunkSize', value)}
                    valueLabelDisplay="auto"
                    step={128}
                    min={128}
                    max={2048}
                  />
                </Box>
                
                <Box sx={{ width: '45%' }}>
                  <Typography gutterBottom>Chunk Overlap: {currentRagSolution.chunkOverlap} tokens</Typography>
                  <Slider
                    value={currentRagSolution.chunkOverlap}
                    onChange={(_, value) => handleSliderChange('chunkOverlap', value)}
                    valueLabelDisplay="auto"
                    step={10}
                    min={0}
                    max={200}
                  />
                </Box>
              </Box>
              
              <Typography variant="subtitle1" sx={{ mt: 3 }}>Retrieval Configuration</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, mt: 2 }}>
                <Box sx={{ width: '45%' }}>
                  <Typography gutterBottom>
                    Top K Results: {currentRagSolution.retrievalConfig.topK}
                  </Typography>
                  <Slider
                    value={currentRagSolution.retrievalConfig.topK}
                    onChange={(_, value) => handleRetrievalConfigChange('topK', value)}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={10}
                  />
                </Box>
                
                <Box sx={{ width: '45%' }}>
                  <Typography gutterBottom>
                    Similarity Threshold: {currentRagSolution.retrievalConfig.similarityThreshold}
                  </Typography>
                  <Slider
                    value={currentRagSolution.retrievalConfig.similarityThreshold}
                    onChange={(_, value) => handleRetrievalConfigChange('similarityThreshold', value)}
                    valueLabelDisplay="auto"
                    step={0.05}
                    min={0}
                    max={1}
                  />
                </Box>
              </Box>
              
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentRagSolution.retrievalConfig.useReranking}
                    onChange={(e) => handleCheckboxChange('useReranking', e.target.checked)}
                  />
                }
                label="Use result reranking"
              />
            </AccordionDetails>
          </Accordion>
          
          <Accordion 
            expanded={expandedSection === 'sources'} 
            onChange={() => setExpandedSection('sources')}
            sx={{ backgroundColor: theme.palette.secondary[800], mb: 2 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Document Sources</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <TextField
                  label="Add Document Source"
                  value={newDocumentSource}
                  onChange={(e) => setNewDocumentSource(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e, handleAddDocumentSource)}
                  sx={{ flex: 1 }}
                  placeholder="Enter document ID or path"
                />
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleAddDocumentSource}
                  disabled={!newDocumentSource.trim()}
                >
                  Add
                </Button>
              </Box>
              <Box sx={{ mt: 2 }}>
                {currentRagSolution.documentSources.length === 0 ? (
                  <Typography variant="body2" color="text.secondary">
                    No document sources added yet.
                  </Typography>
                ) : (
                  currentRagSolution.documentSources.map((source, index) => (
                    <Chip
                      key={index}
                      label={source}
                      onDelete={() => handleDeleteDocumentSource(source)}
                      sx={{ m: 0.5 }}
                    />
                  ))
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
          
          <Accordion 
            expanded={expandedSection === 'dates'} 
            onChange={() => setExpandedSection('dates')}
            sx={{ backgroundColor: theme.palette.secondary[800], mb: 2 }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Dates</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                  <DatePicker
                    label="Created Date"
                    value={currentRagSolution.createdDate}
                    onChange={(date) => handleDateChange('createdDate', date)}
                    slotProps={{ textField: { fullWidth: true, sx: { flex: '1 1 200px' } } }}
                  />
                  <DatePicker
                    label="Last Modified Date"
                    value={currentRagSolution.lastModified}
                    onChange={(date) => handleDateChange('lastModified', date)}
                    slotProps={{ textField: { fullWidth: true, sx: { flex: '1 1 200px' } } }}
                  />
                </Box>
              </LocalizationProvider>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleSaveRagSolution} 
            color="primary" 
            disabled={!currentRagSolution.name}
          >
            {isEditing ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* RAG Creation Wizard */}
      <RagCreationWizard
        open={openWizard}
        onClose={handleCloseWizard}
        onCreateRagSolution={handleCreateRagSolution}
      />
    </div>
  );
};

export default RagSolutionManagementGrid;